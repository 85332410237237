<template>
  <div class="advanced-cookie-banner" v-if="!chosenCookies.isUpdated">
    <div class="advanced-cookie-banner__default" v-if="!isCustom">
      <p>
        {{ infoText }}
        <a
          class="link-body link-body--regular"
          v-if="readMoreUrl"
          :href="readMoreUrl"
          >Les mer om vår bruk av informasjonskapsler her</a
        >.
      </p>
      <div v-if="!isCustom" class="advanced-cookie-banner__default--actions">
        <button class="button button--primary" @click="onAcceptAll">
          Godta alle
        </button>
        <button class="button button--primary" @click="isCustom = true">
          Tilpass dine valg
        </button>
        <button class="button button--primary" @click="onOnlyEssential">
          Kun nødvendige
        </button>
      </div>
    </div>
    <div class="advanced-cookie-banner__custom" v-if="isCustom">
      <div v-if="isCustom" class="advanced-cookie-banner__choices">
        <ChoiceInput
          id="advanced-cookie-banner__essential"
          :label="essentialTitle"
          name="Essensielle"
          :description="essentialDescription"
          v-model="chosenCookies.isEssentials"
          disabled
        />
        <ChoiceInput
          id="advanced-cookie-banner__analytics"
          :label="analyticsTitle"
          name="Analyse"
          :description="analyticsDescription"
          v-model="chosenCookies.isAnalytics"
        />
        <ChoiceInput
          id="advanced-cookie-banner__marketing"
          :label="marketingTitle"
          name="Markedsføring"
          :description="marketingDescription"
          v-model="chosenCookies.isMarketing"
        />
      </div>
      <button class="button button--primary" @click="onSave" primary>
        Bekreft mine valg
      </button>
    </div>
  </div>
</template>

<script>
import { getCookie, setCookie } from "./cookies";
import ChoiceInput from "./components/ChoiceInput";

const initialCookieObject = {
  isUpdated: false,
  isEssentials: true,
  isAnalytics: false,
  isMarketing: false,
};

export default {
  name: "AdvancedCookieBanner",
  components: {
    ChoiceInput,
  },
  props: {
    initialText: {
      type: String,
      default: null,
    },
    editText: {
      type: String,
      default: null,
    },
    readMoreUrl: {
      type: String,
      default: null,
    },
    essentialTitle: {
      type: String,
      required: false,
      default: null,
    },
    essentialDescription: {
      type: String,
      required: false,
      default: null,
    },
    analyticsTitle: {
      type: String,
      required: false,
      default: null,
    },
    analyticsDescription: {
      type: String,
      required: false,
      default: null,
    },
    marketingTitle: {
      type: String,
      required: false,
      default: null,
    },
    marketingDescription: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isCustom: false,
      chosenCookies: {
        isUpdated: false,
        isEssentials: true,
        isAnalytics: false,
        isMarketing: false,
      },
    };
  },
  computed: {
    infoText() {
      return this.isCustom ? this.editText : this.initialText;
    },
  },
  methods: {
    onAcceptAll() {
      this.chosenCookies.isEssentials = true;
      this.chosenCookies.isAnalytics = true;
      this.chosenCookies.isMarketing = true;
      this.onSave();
    },
    onSave() {
      this.chosenCookies.isUpdated = true;
      const content = JSON.stringify(this.chosenCookies);
      setCookie({
        name: "bademiljo-cookie-settings",
        content: content,
        secure: true,
      });
    },
    onChoose(property, value) {
      this.chosenCookies[property] = value;
    },
    onOnlyEssential() {
      this.chosenCookies = initialCookieObject;
      this.onSave();
    },
  },
  created() {
    const cookieContent = getCookie("bademiljo-cookie-settings");
    if (cookieContent) {
      const cookieObject = JSON.parse(cookieContent);
      this.chosenCookies = cookieObject;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../Frontend/scss/mediaqueries";
@import "../../../../Frontend/scss/colors";
@import "../../../../Frontend/scss/typography";
@import "../../../../Frontend/scss/components/button";
@import "../../../../Frontend/scss/components/links";

.advanced-cookie-banner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 250px;
  }

  &__default,
  &__custom {
    color: black;
    padding: 2rem;
    background-color: white;
    box-shadow: 1px 1px 2px 2px #0000005e;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__default {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    &--actions {
      display: flex;

      @include smMax {
        flex-direction: column;
      }

      button {
        margin-right: 1rem;

        @include smMax {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__custom {
    max-width: 1140px;
  }
}
</style>
