const setCookie = (params) => {
  let content =
    params.name && params.content ? `${params.name}=${params.content}` : "";
  let secure = params.secure ? `;Secure` : "";
  let path = params.path ? `;path=${params.path}` : ";path=/";

  if (content || secure) {
    document.cookie = `${content}${secure}${path}`;
  }
};

const getCookie = (name) => {
  let content = null;

  if (name && document.cookie) {
    const cookie = document.cookie
      .split("; ")
      .find((item) => item.startsWith(name));
    if (cookie) {
      content = cookie.split("=")[1];
    }
  }

  return content;
};

export { getCookie, setCookie };
