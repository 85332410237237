<template>
  <div class="advanced-cookie-banner-choice-input">
    <label :for="id" class="advanced-cookie-banner-choice-input__label">{{
      label
    }}</label>
    <input
      type="checkbox"
      :name="name"
      :id="id"
      :checked="modelValue"
      :disabled="disabled"
      class="advanced-cookie-banner-choice-input__input"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <p class="advanced-cookie-banner-choice-input__additional-description">
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ChoiceInput",
  props: {
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update"],
};
</script>

<style lang="scss" scoped>
.advanced-cookie-banner-choice-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 0;
  margin-right: 2rem;

  &__label {
    margin: 0 1rem 0 0;
    font-weight: bold;
  }

  &__input {
    width: 1rem;
    height: 1rem;
  }

  &__additional-description {
    width: 100%;
    margin-top: 0.5rem;
  }
}
</style>
