import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";

const config = window.advancedCookieBanner;
const initialText = config?.initialText ? config.initialText : null;
const readMoreUrl = config?.readMoreUrl ? config.readMoreUrl : null;
const editText = config?.editText ? config.editText : null;
const essentialTitle = config?.essentialTitle ? config.essentialTitle : null;
const essentialDescription = config?.essentialDescription
  ? config.essentialDescription
  : null;
const analyticsTitle = config?.analyticsTitle ? config.analyticsTitle : null;
const analyticsDescription = config?.analyticsDescription
  ? config.analyticsDescription
  : null;
const marketingTitle = config?.marketingTitle ? config.marketingTitle : null;
const marketingDescription = config?.marketingDescription
  ? config.marketingDescription
  : null;

createApp(App, {
  initialText,
  editText,
  essentialTitle,
  essentialDescription,
  analyticsTitle,
  analyticsDescription,
  marketingTitle,
  marketingDescription,
  readMoreUrl,
})
  .use(store)
  .mount("#advanced-cookie-banner");

const advancedCookieBannerdocumentTriggers = document.querySelectorAll(
  "[data-open-advanced-cookie-banner], [href$='#open-advanced-cookie-banner']"
);

const onTrigged = (event) => {
  event.preventDefault();
  let content = null;
  const cookie = document.cookie
    .split("; ")
    .find((item) => item.startsWith("bademiljo-cookie-settings"));

  if (cookie) {
    content = cookie.split("=")[1];
    const contentObject = JSON.parse(content);
    contentObject.isUpdated = false;
    const contentString = JSON.stringify(contentObject);
    document.cookie =
      "bademiljo-cookie-settings=" + contentString + ";Secure;path=/";
  }
  window.location.reload();
};

if (advancedCookieBannerdocumentTriggers.length) {
  advancedCookieBannerdocumentTriggers.forEach((item) => {
    item.addEventListener("click", onTrigged);
  });
}
